import React from "react";
import ReactDOM from "react-dom";
import ReactGA from "react-ga";

import ReactTimeAgo from "react-time-ago";
import JavascriptTimeAgo from "javascript-time-ago";

import GoogleLogin, { GoogleLogout } from "react-google-login";

/*
https://medium.com/@rocksinghajay/login-with-facebook-and-google-in-reactjs-990d818d5dab

import FacebookLogin from 'react-facebook-login';

const responseFacebook = (response) => {
  console.log(response);
}
<FacebookLogin
  appId="445642496206200"
  fields="name,email,picture"
  callback={responseFacebook}
/>
*/

// The desired locales.
import en from "javascript-time-ago/locale/en";

import "./styles.css";

function App() {
  initializeReactGA();
  return (
    <div className="App">
      <h1>
        <pre>@coinexthour</pre>
      </h1>
      <LoggedInOut />
    </div>
  );
}

function initializeReactGA() {
  ReactGA.initialize("UA-142143009-1");
  ReactGA.pageview("/");
}

class LoggedInOut extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLogged: false
    };
  }

  componentDidMount() {
    this.setState({ isLogged: false });
  }

  render() {
    const { isLogged } = this.state;
    if (isLogged) {
      return this.renderLoggedIn();
    }
    return this.renderLoggedOut();
  }

  renderLoggedIn() {
    const responseGoogle = response => {
      console.log(response);
      this.setState({ isLogged: false });
    };
    return (
      <div>
        <GoogleLogout onLogoutSuccess={responseGoogle} />
        <br />
        <br />
        <MyComponent />
      </div>
    );
  }

  renderLoggedOut() {
    const responseGoogle = response => {
      console.log(response);
      this.setState({ isLogged: true });
    };
    const responseGoogleFail = response => {
      console.log(response);
      this.setState({ isLogged: false });
    };
    return (
      <GoogleLogin
        clientId="993725257159-v70f41ibqbqpmvnsap5n0in6lrf4smmp" //CLIENTID NOT CREATED YET
        buttonText="Login with Google"
        onSuccess={responseGoogle}
        onFailure={responseGoogleFail}
      />
    );
  }
}

class MyComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      // items: []
      prediction: "0",
      timestamp: ""
    };
  }

  componentDidMount() {
    fetch(
      "https://p0ztnkfv57.execute-api.us-east-1.amazonaws.com/prod/botinvest"
    )
      .then(res => res.json())
      .then(
        result => {
          this.setState({
            isLoaded: true,
            prediction: result.body.prediction,
            timestamp: result.body.timestamp
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        error => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      );
  }

  render() {
    const { error, isLoaded, prediction, timestamp } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div>Loading...</div>;
    } else {
      var p = "Down";
      if (prediction > 0) {
        p = "Up";
      }
      var date = new Date(timestamp + "Z");
      JavascriptTimeAgo.locale(en);
      return (
        <div>
          <div className={p}>BTC {p}</div>
          <p>
            Last update: <ReactTimeAgo date={date} />
          </p>
        </div>
      );
    }
  }
}

const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);
